import { RouteRecordRaw } from 'vue-router';
import { companyRoutesDictionary } from './company-routes.dictionary.ts';

export const companiesRoutesConfig: RouteRecordRaw = {
  ...companyRoutesDictionary.company.root,
  redirect: { name: companyRoutesDictionary.company.list.name },
  children: [
    {
      ...companyRoutesDictionary.company.list,
      component: () => import('../pages/List/ListCompanyPage.vue'),
    },
    {
      ...companyRoutesDictionary.company.create,
      component: () => import('../pages/Create/CreateCompanyPage.vue'),
    },
    {
      ...companyRoutesDictionary.company.update,
      component: () => import('../pages/Edit/EditCompanyPage.vue'),
      props: true,
    },
    {
      ...companyRoutesDictionary.company.detail,
      component: () => import('../pages/Detail/CompanyDetailPage.vue'),
      props: true,
    },
    {
      ...companyRoutesDictionary.company.setCreditLimits,
      component: () =>
        import('../pages/SetCreditLimits/SetCreditLimitsPage.vue'),
      props: true,
    },
  ],
};
