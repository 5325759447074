import { moduleRouteDictionaryFactory } from '@tundr/routing';

export const companyRoutesDictionary = moduleRouteDictionaryFactory(
  'company',
  'companies',
  {
    list: 'list',
    create: 'create',
    update: 'update/:id',
    setCreditLimits: 'set-credit-limits/:companyId',
    detail: 'detail/:id',
  },
);
