import { moduleRouteDictionaryFactory } from '@tundr/routing';

export const usersRoutesDictionary = moduleRouteDictionaryFactory(
  'users',
  'users',
  {
    list: 'list',
    detail: 'detail/:userId',
    employee: 'employee/:employeeId',
    hr: 'hr/:hrId',
  },
);
