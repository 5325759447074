import { RouteRecordRaw } from 'vue-router';
import { usersRoutesDictionary } from './users-routes.dictionary.ts';

export const usersRoutesConfig: RouteRecordRaw = {
  ...usersRoutesDictionary.users.root,
  redirect: { name: usersRoutesDictionary.users.list.name },
  children: [
    {
      ...usersRoutesDictionary.users.list,
      component: () => import('../pages/List/UserListPage.vue'),
    },
    {
      ...usersRoutesDictionary.users.detail,
      props: true,
      component: () => import('../pages/Detail/UserDetailPage.vue'),
    },
    {
      ...usersRoutesDictionary.users.employee,
      component: () =>
        import('../pages/UserEmployeeDetail/UserEmployeeDetailPage.vue'),
      props: true,
    },
    {
      ...usersRoutesDictionary.users.hr,
      component: () => import('../pages/UserHrDetail/UserHrDetailPage.vue'),
      props: true,
    },
  ],
};
